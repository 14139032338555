@import-normalize;
/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap"); */

:root {
  --background: #fff;
  --text-primary: #000;
  --text-secondary: #808080;
  --text-disabled: lightgray;
  --theme-color: cornflowerblue;
}

[data-theme="dark"] {
  --background: #000;
  --text-primary: #fff;
  --text-secondary: #808080;
  --text-disabled: #404040;
}

body {
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  margin: 0;
  user-select: none;
}

table {
  padding: 0px 10px;
  font-size: 1em;
  width: 100%;
  table-layout: fixed;
}

th,
td {
  padding: 5px;
}

/* table,
th,
td {
  border-top: 1px solid var(--text-secondary);
  border-bottom: 1px solid var(--text-secondary);
  border-collapse: collapse;
} */

header {
  height: 51px;
  padding: 0px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--text-secondary);
}

.game-info {
  padding: 10px 16px 0px 16px;
  font-size: 16px;
  color: var(--text-primary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.app {
  height: calc(100 * var(--vh));
  background-color: var(--background);
  color: var(--text-primary);
}

.title {
  align-self: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  position: relative;
}

.streak-text {
  padding: 0px 15px 0px 15px;
}

.icon {
  cursor: pointer;
}

.icon:disabled {
  color: lightgray;
}

.game {
  position: relative;
  text-align: center;
  max-width: 500px;
  height: calc(100% - 51px);
  /* height: calc((var(--vh, 1vh) * 100) - 51px); */
  margin: auto;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%; /* Fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100); */
  background-color: var(--background);
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.overlay-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay p {
  padding: 8px 16px;
}

.modal {
  padding: 5px 10px;
  position: absolute;
  background-color: var(--background);
  border: 2px solid var(--text-primary);
  color: var(--text-primary);
}

#board-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#board {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  box-sizing: border-box;
}

.tile {
  border: 2px solid var(--text-primary);
  aspect-ratio: 1;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: bold;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 5px;
}

.tile:disabled {
  color: lightgray;
  border: 2px solid lightgray;
}

.game-button {
  background: none;
  border: 2px solid var(--text-primary);
  color: var(--text-primary);
  padding: 5px 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;
}

.button-container {
  padding: 5px;
}

.play-button {
  background: var(--theme-color);
  border: 2px solid var(--text-primary);
  color: var(--text-primary);
  padding: 7px 15px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;
}

.play-button:disabled {
  background: var(--background);
  color: var(--text-disabled);
  border: 2px solid var(--text-disabled);
  cursor: not-allowed;
}

.solution-button {
  background: none;
  border: 2px solid var(--text-primary);
  color: var(--text-primary);
  padding: 5px 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;
}

.puzzle-button {
  background: none;
  border: 2px solid var(--text-primary);
  color: var(--text-primary);
  padding: 7px 15px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;
}

.share-button {
  background: none;
  border: 2px solid green;
  color: green;
  padding: 5px 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;
}

.solution-container {
  padding: 10px;
}

.solution-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.solution {
  padding: 3px;
  font-size: 1.2em;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: green;
}

.solution-info {
  color: var(--text-secondary);
  padding: 60px 0;
}

.xmark {
  padding: 16px;
  position: absolute;
  top: 0px;
  right: 0px;
}
